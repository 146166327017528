import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  onMobile = false;
  screenWidth: number = window.innerWidth;

  constructor(private router: Router) { }

  ngOnInit(): void {
    document.addEventListener("click", function(e: MouseEvent) {
      const targetElement = e.target as Element;
      if (targetElement.tagName === "A" && !targetElement.hasAttribute("target")) {
        console.log('opening a link in new tab!')
        targetElement.setAttribute("target", "_blank");
      }
    });

    if (window.innerWidth < 1279) {
      this.onMobile = true;
    }

    if (localStorage.getItem('loggedIn') === null && !this.onMobile) {
      this.router.navigate(['login']);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.screenWidth = window.innerWidth;
    if (window.innerWidth < 1279) {
      this.onMobile = true;
    } else {
      this.onMobile = false;
    }
  }
}
