import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BlockType} from '../../views/timeline/timeline.component';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  activeBlockId$ = new Subject<string>();
  blocks$ = new BehaviorSubject<BlockType[]>([]);

  setBlocks(blocks: BlockType[]): void {
    this.blocks$.next(blocks);
  }

  getBlocks(): Subject<BlockType[]> {
    return this.blocks$;
  }

  setActiveBlockId(id: string): void {
    this.activeBlockId$.next(id);
  }

  getActiveBlockId(): Subject<string> {
    return this.activeBlockId$;
  }
}
