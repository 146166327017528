import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

export interface NewPeriod {
  period: number;
  navigated: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ActivePeriodService {

  private activePeriod$ = new Subject<NewPeriod>()

  setActivePeriod(period: number, navigated: boolean): void {
    const newPeriod: NewPeriod = {period, navigated};

    this.activePeriod$.next(newPeriod);
  }

  getActivePeriod(): Observable<NewPeriod> {
    return this.activePeriod$;
  }
}
