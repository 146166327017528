<div class="period-box">
  <div class="category-wrapper">
    <div class="category category-povo">POVO</div>
    <div class="category category-vo">VO</div>
    <div class="category category-po">PO</div>
  </div>
  <div class="period-wrapper">
    <div class="period" *ngFor="let period of periodIndexes; let i = index;" [ngClass]="activePeriod === (i + 1) ? 'number-' + (i + 1): ''" [ngStyle]="{'left': 10 + (20 * i) + 'px'}">
      <div *ngIf="i + 1 <= activePeriod">
        <div class="period-number" [ngStyle]="{'bottom': 150 - (20 * (i + 1)) + 1 + 'px'}" [class.active]="i + 1 === activePeriod">
          <p>{{ i + 1 }}</p>
        </div>
        <div class="period-line" [ngStyle]="{'height': 120 - (20 * (i + 1)) + 'px'}"></div>
        <div class="period-bullet"></div>
      </div>
    </div>
  </div>
</div>

<div class="scrollable" appHorizontalScroll #scrollable>
  <div class="wrapper">
    <app-timeline-day
      #timelineDay id="{{'day-' + days[0]}}"
      *ngFor="let days of filteredBlocksByDays; let i = index"
      [blocks]="days[1]"
      [activeIndex]="indexes[currentIndex] === days[0]"
      [period]="periodIndexes.indexOf(i) + 1"
    ></app-timeline-day>
  </div>
</div>

<div class="timeline">
  <div class="button-wrapper" *ngIf="!showBlockDetail">
    <div class="button" (click)="scrollToPreviousItem()">
      <i class="fa-light fa-circle-arrow-left"></i>
      <p>Vorige dag</p>
    </div>
    <div class="button" (click)="scrollToNextItem()">
      <p>Volgende dag</p>
      <i class="fa-light fa-circle-arrow-right"></i>
    </div>
  </div>

  <div class="back-button" *ngIf="showBlockDetail" (click)="showTimeline()">
    <i class="fa-regular fa-arrow-up"></i>
    <p>Terug</p>
  </div>
</div>
