import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavComponent } from './views/core/side-nav/side-nav.component';
import { TimelineComponent } from './views/timeline/timeline.component';
import { HeaderComponent } from './views/core/header/header.component';
import { StandardBlockComponent } from './views/blocks/standard-block/standard-block.component';
import {HorizontalScrollDirective} from './shared/directives/horizontal-scroll.directive';
import { TimelineDayComponent } from './views/timeline/timeline-day/timeline-day.component';
import { BlockDetailComponent } from './views/block-detail/block-detail.component';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache, from} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from '../environments/environment';
import {ErrorService} from './shared/services/error.service';
import { onError } from "@apollo/client/link/error";
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import {HttpClientModule} from '@angular/common/http';
import {OverlayModule} from '@angular/cdk/overlay';
import { DocumentRendererComponent } from './shared/components/document-renderer/document-renderer.component';
import { DocumentsComponent } from './views/details/documents/documents.component';
import { VideosComponent } from './views/details/videos/videos.component';
import { HomeComponent } from './views/core/home/home.component';
import { DetailsComponent } from './views/details/details.component';
import {WelcomeModalComponent} from './shared/modals/welcome-modal/welcome-modal.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { ThemesComponent } from './views/details/themes/themes.component';
import {ReactiveFormsModule} from '@angular/forms';
import { SearchModalComponent } from './shared/modals/search-modal/search-modal.component';
import {HighlightDirective} from './shared/directives/highlight.directive';
import {RouterModule} from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { NewslettersComponent } from './views/details/newsletters/newsletters.component';

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    HeaderComponent,
    TimelineComponent,
    HeaderComponent,
    StandardBlockComponent,
    HorizontalScrollDirective,
    TimelineDayComponent,
    BlockDetailComponent,
    ErrorDialogComponent,
    DocumentRendererComponent,
    DocumentsComponent,
    VideosComponent,
    HomeComponent,
    DetailsComponent,
    WelcomeModalComponent,
    ThemesComponent,
    SearchModalComponent,
    HighlightDirective,
    LoginComponent,
    HighlightDirective,
    NewslettersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ApolloModule,
    OverlayModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, errorService: ErrorService) {
        const link = httpLink.create({ uri: environment.graphql_endpoint });
        const errorLink = onError(({ graphQLErrors, networkError }) => {
          if (networkError) {
            errorService.displayError('Er kan niet met de server gecommuniceerd worden.', 'Controleer uw verbinding en probeer het opnieuw.');
          }

          if (graphQLErrors) {
            if (!environment.production) {
              console.error(graphQLErrors);
            }

            Sentry.captureException(new Error('GraphQL error'), scope => {
              scope.setContext('errors', {errors: JSON.stringify(graphQLErrors)});
              scope.setLevel('error');
              return scope;
            });
          }
        });
        return {
          cache: new InMemoryCache(),
          link: from([errorLink, link]),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as ApolloClientOptions<any>;
      },
      deps: [HttpLink, ErrorService]
    },
    { provide: LOCALE_ID, useValue: "nl-NL" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
