import { Component, OnInit } from '@angular/core';
import {gql} from '../../../../gql';
import {Apollo} from 'apollo-angular';
import {catchError, EMPTY} from 'rxjs';
import {NewsletterComponent_NewsletterQuery} from '../../../../gql/graphql';

const NEWSLETTER_QUERY = gql(/* GraphQL */  `
    query NewsletterComponent_newsletter {
      getCampaigns {
        date
        title
        url
      }
    }
`);

// @ts-expect-error [0] does not exist on type
export type NewsletterType = NewsletterComponent_NewsletterQuery['getCampaigns'][0];

@Component({
  selector: 'app-newsletters',
  templateUrl: './newsletters.component.html',
  styleUrls: ['./newsletters.component.scss']
})
export class NewslettersComponent implements OnInit {
  newsletters: NewsletterType[] = [];

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.apollo.watchQuery({
      query: NEWSLETTER_QUERY
    }).valueChanges.pipe(
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    ).pipe().subscribe(output => {
      this.newsletters = [...output.data.getCampaigns as NewsletterType[]].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    });

  }

}
