import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import data from 'data.json'
import moment from 'moment';
import {catchError, debounceTime, EMPTY, fromEvent, Subscription, throttleTime} from 'rxjs';
import {BlockDetailSelection, BlockDetailService} from '../../shared/services/block-detail.service';
import {ActivePeriodService} from '../../shared/services/active-period.service';
import {TimelineFilterService} from '../../shared/services/timeline-filter.service';
import {Apollo} from 'apollo-angular';
import {gql} from '../../../gql';
import {BlockCategoryType, TimelineComponent_BlocksQuery} from '../../../gql/graphql';
import {SearchService} from '../../shared/services/search.service';
import {ActivatedRoute} from '@angular/router';

const BLOCK_QUERY = gql(/* GraphQL */ `
  query TimelineComponent_blocks {
    blocks {
      id
      category
      coverText {
        document(hydrateRelationships: true)
      }
      details {
        document(hydrateRelationships: true)
      }
      videoUrlCode
      beginDate
      endDate
      period
      gathering
      gatheringDate
      downloadLink {
        url
      }
    }
  }
`);

// @ts-expect-error [0] does not exist on type
export type BlockType = TimelineComponent_BlocksQuery['blocks'][0];

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, AfterViewInit {

  @ViewChildren('timelineDay') private elementList: QueryList<ElementRef> | null = null;
  @ViewChild('scrollable') private scrollable: ElementRef | null = null;

  data = data;
  blocksByDays: [string, BlockType[]][] = [];
  filteredBlocksByDays: [string, BlockType[]][] = [];
  indexes: string[] = [];
  offsetIndexes: number[] = [];
  currentIndex = 0;
  subscriptions: Subscription[] = [];
  activeBlock: BlockType | null = null;
  showBlockDetail = false;
  activePeriod = 1;
  periodIndexes: number[] = [];

  constructor(private blockDetailService: BlockDetailService,
              private activePeriodService: ActivePeriodService,
              private timelineFilterService: TimelineFilterService,
              private apollo: Apollo,
              private searchService: SearchService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchService.getActiveBlockId().subscribe(output => {
        this.scrollToActiveBlockIndex(output);
      }),
      this.apollo.watchQuery({
        query: BLOCK_QUERY
      }).valueChanges.pipe(
        catchError(error => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(output => {
        this.searchService.setBlocks(output.data.blocks as BlockType[]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const blockDict: {[key: string]: any[]} = {}
        const today: moment.Moment = moment();
        output.data.blocks?.forEach((block) => {
          const beginDate: moment.Moment = moment(block.beginDate, 'DD-MM-YYYY');
          const endDate: moment.Moment = moment(block.endDate, 'DD-MM-YYYY');
          let displayDate = beginDate;

          if (beginDate.isBefore(today) && !moment(endDate).isBefore(moment(), "day")) {
            displayDate = today;
          }

          if (blockDict[displayDate.format('DD-MM-YYYY')]) {
            blockDict[displayDate.format('DD-MM-YYYY')].push(block);
          } else {
            blockDict[displayDate.format('DD-MM-YYYY')] = [];
            blockDict[displayDate.format('DD-MM-YYYY')].push(block);
          }
        });

        this.blocksByDays = Object.entries(blockDict).sort(([dateA,],[dateB,]) => moment(dateA, 'DD-MM-YYYY').isBefore(moment(dateB, 'DD-MM-YYYY')) ? -1 : 1);
        this.blocksByDays.forEach(day => day[1].sort((a, b) => moment(a.beginDate, 'DD-MM-YYYY').isBefore(moment(b.beginDate, 'DD-MM-YYYY'))? -1 : 1));
        this.filteredBlocksByDays = [...this.blocksByDays];

        setTimeout(() => {
          this.calculateOffsets();
          this.calculatePeriodIndexes();
          this.calculateIndexes('auto');
        });


        this.blockDetailService.getBlockDetailSelection().subscribe((output: BlockDetailSelection) => {
          this.activeBlock = output.block;
          this.showBlockDetail = output.showBlockDetail;
        })

        this.timelineFilterService.getTimelineFilter().subscribe(output => {
          if (output === BlockCategoryType.POVO) {
            this.filteredBlocksByDays = this.blocksByDays;
          } else {
            this.filteredBlocksByDays = this.blocksByDays.map(day => ([day[0], day[1].filter(block => block.category === output || block.category === BlockCategoryType.POVO)]))
            this.filteredBlocksByDays = this.filteredBlocksByDays.filter(day => day[1].length !== 0)
          }
          this.searchService.setBlocks(this.filteredBlocksByDays.map(day => day[1]).flat() as BlockType[]);
          setTimeout(() => {
            this.calculateOffsets();
            this.calculatePeriodIndexes();
            this.calculateIndexes('smooth');
          });
        });

        this.activePeriodService.getActivePeriod().subscribe(output => {
          if (!output.navigated) {
            return;
          }
          this.currentIndex = this.filteredBlocksByDays.findIndex(day => day[1][0].period === output.period);
          this.scrollToIndex('smooth');
        });
        setTimeout(() => {
          this.route.queryParamMap.subscribe(params => {
            const blockId = params.get('share');
            if (blockId) {
              this.searchService.setActiveBlockId(blockId);
            }
          })
        }, 500);
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.scrollable) {
      fromEvent(this.scrollable.nativeElement, 'scroll').pipe(
        throttleTime(200),
        debounceTime(1000)
      )
        .subscribe(output => {
          const closest = this.offsetIndexes.reduce(function(prev, curr) {
            // @ts-expect-error output is of type unknown
            return (Math.abs(curr - output.target.scrollLeft) < Math.abs(prev - output.target.scrollLeft) ? curr : prev);
          });

          this.currentIndex = this.offsetIndexes.indexOf(closest);

          // check if scrolled to the end of the timeline, and return so last block can be viewed
          // @ts-expect-error output is of type unknown
          if (output.target.clientWidth + output.target.scrollLeft === output.target.scrollWidth) {
            // this is used to still update the active period
            if (this.activePeriod !== this.filteredBlocksByDays[this.currentIndex][1][0].period) {
              this.activePeriod = this.filteredBlocksByDays[this.currentIndex][1][0].period
              this.activePeriodService.setActivePeriod(this.activePeriod, false);
            }
            return;
          }

          // this.scrollToIndex('smooth');
          // needed because scrollToIndex is not triggered anymore
          if (this.activePeriod !== this.filteredBlocksByDays[this.currentIndex][1][0].period) {
            this.activePeriod = this.filteredBlocksByDays[this.currentIndex][1][0].period
            this.activePeriodService.setActivePeriod(this.activePeriod, false);
          }
        });
    }
  }

  calculateIndexes(behavior: string): void {
    this.indexes = [];

    const today: moment.Moment = moment();

    this.filteredBlocksByDays.forEach(day => {
      this.indexes.push(day[0]);
    });

    let nearestDate = '';
    this.indexes.forEach(date => {
      const diff = moment(date, 'DD-MM-YYYY').diff(moment(today.format('YYYY-MM-DD')), 'days');
      if (diff >= 0) {
        if (nearestDate) {
          if (moment(date, 'DD-MM-YYYY').diff(moment(nearestDate, 'DD-MM-YYYY'), 'days') < 0) {
            nearestDate = date;
          }
        } else {
          nearestDate = date;
        }
      }
    });
    this.currentIndex = this.indexes.indexOf(nearestDate);

    setTimeout(() => {
      this.scrollToIndex(behavior);
    });
  }

  calculateOffsets(): void {
    this.offsetIndexes = [];
    if (this.elementList) {
      this.elementList.forEach(element => {
        // @ts-expect-error element.elementRef is not a known property
        this.offsetIndexes.push(element.elementRef.nativeElement.offsetLeft);
      });
    }
  }

  calculatePeriodIndexes(): void {
    this.periodIndexes = [];
    let period = 0;
    this.filteredBlocksByDays.forEach((day, index) => {
      if (day[1][0].period !== period) {
        period = day[1][0].period;
        this.periodIndexes.push(index);
      }
    });
  }

  scrollToIndex(behavior: string): void {
    const scrollEl: HTMLElement | null = document.getElementById('day-' + this.indexes[this.currentIndex]) ?? null;
    if (scrollEl !== null) {
      if (behavior === 'smooth') {
        scrollEl.scrollIntoView({
          behavior: 'smooth',
          inline: 'start'
        });
      } else {
        scrollEl.scrollIntoView({
          inline: 'start'
        });
      }
    }

    if (this.activePeriod !== this.filteredBlocksByDays[this.currentIndex][1][0].period) {
      this.activePeriod = this.filteredBlocksByDays[this.currentIndex][1][0].period
      this.activePeriodService.setActivePeriod(this.activePeriod, false);
    }
  }

  scrollToActiveBlockIndex(id: string): void {
    this.filteredBlocksByDays.forEach(day => {
      day[1].forEach(block => {
        if (block.id === id) {
          this.currentIndex = this.filteredBlocksByDays.indexOf(day);
          this.scrollToIndex('smooth');
        }
      })
    })
  }

  scrollToNextItem() {
    this.currentIndex ++;
    if (this.currentIndex >= this.indexes.length) {
      this.currentIndex = (this.indexes.length -1);
    }
    this.scrollToIndex('smooth');
  }

  scrollToPreviousItem() {
    this.currentIndex --;
    if (this.currentIndex < 0) {
      this.currentIndex = 0;
      return;
    }
    this.scrollToIndex('smooth');
  }

  showTimeline() {
    if (this.activeBlock) {
      this.blockDetailService.setBlockDetailSelection(this.activeBlock, false)
    }
  }
}
