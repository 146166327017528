import {Component, Input, OnInit} from '@angular/core';
import {BlockDetailService} from '../../../shared/services/block-detail.service';
import {BlockType} from '../../timeline/timeline.component';
import moment from 'moment';
import {SearchService} from '../../../shared/services/search.service';
import {BlockCategoryType} from '../../../../gql/graphql';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-standard-block',
  templateUrl: './standard-block.component.html',
  styleUrls: ['./standard-block.component.scss']
})
export class StandardBlockComponent implements OnInit {

  @Input() block: BlockType | null = null;
  beginDate = '';
  endDate = '';
  gatheringDate = '';
  showActive = false;
  animationActive = false;
  animationTimeout: ReturnType<typeof setTimeout> | undefined;
  copied = false;
  constructor(private blockDetailService: BlockDetailService,
              private searchService: SearchService,
              private clipboard: Clipboard) { }

  ngOnInit(): void {
    if (this.block) {
      this.beginDate = moment(this.block.beginDate, 'DD-MM-YYYY').toString();
      this.endDate = moment(this.block.endDate, 'DD-MM-YYYY').toString();
      this.gatheringDate = moment(this.block.gatheringDate, 'DD-MM-YYYY').toString();
    }

    this.searchService.getActiveBlockId().subscribe(output => {
      this.showActive = output === this.block.id;
      this.animationActive = true;

      if (this.animationTimeout) {
        clearTimeout(this.animationTimeout);
      }

      this.animationTimeout = setTimeout(() => {
        this.animationActive = false;
      }, 10000);

    });
  }

  copyToClipboard(): void {
    this.copied = true;
    this.clipboard.copy(window.location.origin + '?share=' + this.block?.id);
    setTimeout(() => {
      this.copied = false;
    }, 1000)
  }

  getAnimationStyle(): string {
    if (this.showActive && this.animationActive && this.block) {
      if (this.block.category === BlockCategoryType.POVO) {
        return 'flashingBorderPOVO ';
      } else if (this.block.category === BlockCategoryType.VO) {
        return 'flashingBorderVO ';
      } else if (this.block.category === BlockCategoryType.PO) {
        return 'flashingBorderPO ';
      }
    }
    return ''
  }

  toggleDetailView() {
    if (this.block) {
      this.blockDetailService.setBlockDetailSelection(this.block, true);
    }
  }
}
