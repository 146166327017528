import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivePeriodService} from '../../../shared/services/active-period.service';
import {Apollo} from 'apollo-angular';
import {HeaderComponent_PeriodsQuery} from '../../../../gql/graphql';
import {catchError, EMPTY, Subscription} from 'rxjs';
import {gql} from '../../../../gql';
import {SearchModalComponent} from '../../../shared/modals/search-modal/search-modal.component';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

const PERIOD_QUERY = gql(/* GraphQL */ `
    query HeaderComponent_periods {
      periods {
        id
        period
        name
        displayDate
      }
    }
`);

type PeriodType = NonNullable<HeaderComponent_PeriodsQuery['periods']>[0] & { showDate: boolean };


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  periods: PeriodType[] = [];
  activePeriod = 1;
  subscriptions: Subscription[] = [];
  isModalOpen = false;

  constructor(private activePeriodService: ActivePeriodService,
              private apollo: Apollo,
              private router: Router,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.activePeriodService.getActivePeriod().subscribe(output => {
        this.activePeriod = output.period;
      }),
      this.apollo.watchQuery({
        query: PERIOD_QUERY
      }).valueChanges.pipe(
        catchError(error => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(output => {
        if (output.data.periods) {
          this.periods = output.data.periods.map(period => ({ ...period, showDate: false }));
          this.periods.sort((a, b) => a.period - b.period);
        }
      }),
    );
  }

  setActivePeriod(period: number): void {
    this.activePeriodService.setActivePeriod(period, true);
  }

  openSearchModal() {
    if (this.isModalOpen) {
      const dialogRef = this.dialog.openDialogs[0];
      dialogRef.addPanelClass('fly-out-dialog');
      dialogRef.close();
      this.isModalOpen = false;
    } else {
      this.router.navigate(['/']);
      const dialog = this.dialog.open(SearchModalComponent, {
        width: '600px',
        maxWidth: '100vw',
        height: 'calc(100% - 80px)',
        position: {
          bottom: '0',
          right: '0'
        },
        hasBackdrop: false,
        enterAnimationDuration: '0ms',
        exitAnimationDuration: '300ms',
        backdropClass: 'disable-backdrop-shadow',
        panelClass: 'fly-in-dialog',
      });

      this.isModalOpen = true;

      dialog.afterClosed().subscribe(() => {
        this.isModalOpen = false;
      });
    }
  }



  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

}
