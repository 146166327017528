import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './views/core/home/home.component';
import {DetailsComponent} from './views/details/details.component';
import {VideosComponent} from './views/details/videos/videos.component';
import {DocumentsComponent} from './views/details/documents/documents.component';
import {ThemesComponent} from './views/details/themes/themes.component';
import {NewslettersComponent} from './views/details/newsletters/newsletters.component';
import {LoginComponent} from './views/login/login.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'details', component: DetailsComponent, children: [
      { path: 'documents', component: DocumentsComponent },
      { path: 'videos', component: VideosComponent },
      { path: 'themes', component: ThemesComponent },
      { path: 'newsletters', component: NewslettersComponent }
    ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
