import {Component, OnDestroy, OnInit} from '@angular/core';
import {TimelineFilterService} from '../../../shared/services/timeline-filter.service';
import {BlockCategoryType} from '../../../../gql/graphql';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  today = new Date();
  blockCategoryType = BlockCategoryType;
  activeCategory: BlockCategoryType = BlockCategoryType.POVO
  subscriptions: Subscription[] = [];

  constructor(private timelineFilterService: TimelineFilterService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    this.timelineFilterService.getTimelineFilter().subscribe(output => {
      this.activeCategory = output;
    })
  }

  setTimelineFilter(filter: BlockCategoryType) {
    this.timelineFilterService.setTimelineFilter(filter);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
