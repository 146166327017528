import {Component, OnDestroy, OnInit} from '@angular/core';
import {gql} from '../../../../gql';
import {Apollo} from 'apollo-angular';
import {catchError, EMPTY, Subscription} from 'rxjs';
import {DocumentsComponent_DocumentsQuery} from '../../../../gql/graphql';

const DOCUMENT_QUERY = gql(/* GraphQL */`
 query DocumentsComponent_documents {
   assets(where: { type: { equals: DOCUMENT } }) {
     id
     name
     type
     file {
       url
     }
     category {
       name
       ordering
     }
   }
 }
`);

export type AssetType = NonNullable<DocumentsComponent_DocumentsQuery['assets']>[0];
export type CategoryType = NonNullable<DocumentsComponent_DocumentsQuery['assets']>[0]['category'];

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  documents: AssetType[] = [];
  groupedDocuments: { category: CategoryType, assets: AssetType[] }[] = [];

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.apollo.watchQuery<DocumentsComponent_DocumentsQuery>({
        query: DOCUMENT_QUERY
      }).valueChanges.pipe(
        catchError(error => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(output => {
        this.groupedDocuments.push({category: {name: 'Algemeen', ordering: 99999}, assets: []})
        output.data.assets?.forEach(asset => {
          if (asset.category) {
            const category = asset.category;
            const existingCategory = this.groupedDocuments.find(groupedDocument => groupedDocument.category?.name === category.name);
            if (existingCategory) {
              existingCategory.assets.push(asset);
            } else {
              this.groupedDocuments.push({category, assets: [asset]});
            }
          } else {
            this.groupedDocuments[0].assets.push(asset);
          }
        });

        this.groupedDocuments.sort((a, b) => {
          const orderingA = a.category ? a.category.ordering : 0;
          const orderingB = b.category ? b.category.ordering : 0;
          return orderingA - orderingB;
        });

        this.groupedDocuments.forEach(groupedDocument => {
          groupedDocument.assets.sort((a, b) => a.name.localeCompare(b.name))
        })
      })
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
