<router-outlet *ngIf="!onMobile"></router-outlet>
<div class="mobile-wrapper" *ngIf="onMobile">
  <div class="middle">
    <div class="text-wrapper">
      <h1><b>De online werkomgeving</b> bezoek je vanaf een desktop of tablet voor <b>optimaal gebruik!</b></h1>
      <p>Wellicht bent u ingezoomd in uw browser. Zoom uit om toegang te krijgen tot de werkomgeving</p>
      <img src="assets/imgs/bovo-logo.png" alt="">
    </div>
    <img src="assets/imgs/mobile/inlog-mobiel-02.png" alt="">
    <img class="top-right" src="assets/imgs/mobile/inlog-mobiel-03.png" alt="">
    <img class="bottom-right" src="assets/imgs/mobile/inlog-mobiel-04.png" alt="">
    <img class="bottom-left" src="assets/imgs/mobile/inlog-mobiel-05.png" alt="">
    <img class="top-left" src="assets/imgs/mobile/inlog-mobiel-01.png" alt="">
  </div>
  <div class="screen-width-wrapper">
    <p>Huidige schermgroote <b>{{ screenWidth }}px</b></p>
    <p>Gewenste schermgroote <b>1280px</b></p>
  </div>

</div>
