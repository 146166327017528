import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BlockType} from '../timeline/timeline.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-block-detail',
  templateUrl: './block-detail.component.html',
  styleUrls: ['./block-detail.component.scss']
})
export class BlockDetailComponent {
  @Input() set block(value: BlockType | null) {
    this._block = value;
    this.videoUrl = value.videoUrlCode !== '' ? this.sanitizeVimeoUrl('https://player.vimeo.com/video/' + value.videoUrlCode + '?title=0&byline=0&portrait=0') : null;
  }
  public _block: BlockType | null = null;

  @ViewChild('wrapper', { static: true }) container!: ElementRef;

  videoUrl: SafeResourceUrl | null = null

  constructor(private sanitizer: DomSanitizer) { }

  sanitizeVimeoUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
