/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date | string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Asset = {
  __typename?: 'Asset';
  category?: Maybe<DocumentCategory>;
  file?: Maybe<FileFieldOutput>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: AssetTypeType;
  videoUrl?: Maybe<Scalars['String']>;
};

export type AssetCreateInput = {
  category?: InputMaybe<DocumentCategoryRelateToOneForCreateInput>;
  file?: InputMaybe<FileFieldInput>;
  name?: InputMaybe<Scalars['String']>;
  type: AssetTypeType;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type AssetOrderByInput = {
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  type?: InputMaybe<OrderDirection>;
  videoUrl?: InputMaybe<OrderDirection>;
};

export enum AssetTypeType {
  DOCUMENT = 'DOCUMENT',
  IMAGE = 'IMAGE',
  THEME = 'THEME',
  VIDEO = 'VIDEO'
}

export type AssetTypeTypeNullableFilter = {
  equals?: InputMaybe<AssetTypeType>;
  in?: InputMaybe<Array<AssetTypeType>>;
  not?: InputMaybe<AssetTypeTypeNullableFilter>;
  notIn?: InputMaybe<Array<AssetTypeType>>;
};

export type AssetUpdateArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type AssetUpdateInput = {
  category?: InputMaybe<DocumentCategoryRelateToOneForUpdateInput>;
  file?: InputMaybe<FileFieldInput>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssetTypeType>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type AssetWhereInput = {
  AND?: InputMaybe<Array<AssetWhereInput>>;
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  OR?: InputMaybe<Array<AssetWhereInput>>;
  category?: InputMaybe<DocumentCategoryWhereInput>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<AssetTypeTypeNullableFilter>;
  videoUrl?: InputMaybe<StringFilter>;
};

export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AuthenticatedItem = User;

export type Block = {
  __typename?: 'Block';
  beginDate: Scalars['String'];
  category: BlockCategoryType;
  coverText?: Maybe<Block_CoverText_Document>;
  createdAt: Scalars['DateTime'];
  details?: Maybe<Block_Details_Document>;
  downloadLink?: Maybe<FileFieldOutput>;
  endDate: Scalars['String'];
  gathering?: Maybe<Scalars['Boolean']>;
  gatheringDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  period: Scalars['Int'];
  videoUrlCode?: Maybe<Scalars['String']>;
};

export enum BlockCategoryType {
  PO = 'PO',
  POVO = 'POVO',
  VO = 'VO'
}

export type BlockCategoryTypeNullableFilter = {
  equals?: InputMaybe<BlockCategoryType>;
  in?: InputMaybe<Array<BlockCategoryType>>;
  not?: InputMaybe<BlockCategoryTypeNullableFilter>;
  notIn?: InputMaybe<Array<BlockCategoryType>>;
};

export type BlockCreateInput = {
  beginDate?: InputMaybe<Scalars['String']>;
  category: BlockCategoryType;
  coverText?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['JSON']>;
  downloadLink?: InputMaybe<FileFieldInput>;
  endDate?: InputMaybe<Scalars['String']>;
  gathering?: InputMaybe<Scalars['Boolean']>;
  gatheringDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  period: Scalars['Int'];
  videoUrlCode?: InputMaybe<Scalars['String']>;
};

export type BlockOrderByInput = {
  beginDate?: InputMaybe<OrderDirection>;
  category?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  endDate?: InputMaybe<OrderDirection>;
  gathering?: InputMaybe<OrderDirection>;
  gatheringDate?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  period?: InputMaybe<OrderDirection>;
  videoUrlCode?: InputMaybe<OrderDirection>;
};

export type BlockUpdateArgs = {
  data: BlockUpdateInput;
  where: BlockWhereUniqueInput;
};

export type BlockUpdateInput = {
  beginDate?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<BlockCategoryType>;
  coverText?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['JSON']>;
  downloadLink?: InputMaybe<FileFieldInput>;
  endDate?: InputMaybe<Scalars['String']>;
  gathering?: InputMaybe<Scalars['Boolean']>;
  gatheringDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Int']>;
  videoUrlCode?: InputMaybe<Scalars['String']>;
};

export type BlockWhereInput = {
  AND?: InputMaybe<Array<BlockWhereInput>>;
  NOT?: InputMaybe<Array<BlockWhereInput>>;
  OR?: InputMaybe<Array<BlockWhereInput>>;
  beginDate?: InputMaybe<StringFilter>;
  category?: InputMaybe<BlockCategoryTypeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endDate?: InputMaybe<StringFilter>;
  gathering?: InputMaybe<BooleanFilter>;
  gatheringDate?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  period?: InputMaybe<IntFilter>;
  videoUrlCode?: InputMaybe<StringFilter>;
};

export type BlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Block_CoverText_Document = {
  __typename?: 'Block_coverText_Document';
  document: Scalars['JSON'];
};


export type Block_CoverText_DocumentDocumentArgs = {
  hydrateRelationships?: Scalars['Boolean'];
};

export type Block_Details_Document = {
  __typename?: 'Block_details_Document';
  document: Scalars['JSON'];
};


export type Block_Details_DocumentDocumentArgs = {
  hydrateRelationships?: Scalars['Boolean'];
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
};

export type Campaign = {
  __typename?: 'Campaign';
  date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CampaignCreateInput = {
  date?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CampaignOrderByInput = {
  date?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  url?: InputMaybe<OrderDirection>;
};

export type CampaignUpdateArgs = {
  data: CampaignUpdateInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpdateInput = {
  date?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CampaignWhereInput = {
  AND?: InputMaybe<Array<CampaignWhereInput>>;
  NOT?: InputMaybe<Array<CampaignWhereInput>>;
  OR?: InputMaybe<Array<CampaignWhereInput>>;
  date?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  title?: InputMaybe<StringFilter>;
  url?: InputMaybe<StringFilter>;
};

export type CampaignWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CreateInitialUserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DocumentCategory = {
  __typename?: 'DocumentCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  ordering: Scalars['Int'];
};

export type DocumentCategoryCreateInput = {
  name?: InputMaybe<Scalars['String']>;
  ordering?: InputMaybe<Scalars['Int']>;
};

export type DocumentCategoryOrderByInput = {
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  ordering?: InputMaybe<OrderDirection>;
};

export type DocumentCategoryRelateToOneForCreateInput = {
  connect?: InputMaybe<DocumentCategoryWhereUniqueInput>;
  create?: InputMaybe<DocumentCategoryCreateInput>;
};

export type DocumentCategoryRelateToOneForUpdateInput = {
  connect?: InputMaybe<DocumentCategoryWhereUniqueInput>;
  create?: InputMaybe<DocumentCategoryCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type DocumentCategoryUpdateArgs = {
  data: DocumentCategoryUpdateInput;
  where: DocumentCategoryWhereUniqueInput;
};

export type DocumentCategoryUpdateInput = {
  name?: InputMaybe<Scalars['String']>;
  ordering?: InputMaybe<Scalars['Int']>;
};

export type DocumentCategoryWhereInput = {
  AND?: InputMaybe<Array<DocumentCategoryWhereInput>>;
  NOT?: InputMaybe<Array<DocumentCategoryWhereInput>>;
  OR?: InputMaybe<Array<DocumentCategoryWhereInput>>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  ordering?: InputMaybe<IntFilter>;
};

export type DocumentCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FileFieldInput = {
  upload: Scalars['Upload'];
};

export type FileFieldOutput = {
  __typename?: 'FileFieldOutput';
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  url: Scalars['String'];
};

export type IdFilter = {
  equals?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type KeystoneAdminMeta = {
  __typename?: 'KeystoneAdminMeta';
  list?: Maybe<KeystoneAdminUiListMeta>;
  lists: Array<KeystoneAdminUiListMeta>;
};


export type KeystoneAdminMetaListArgs = {
  key: Scalars['String'];
};

export type KeystoneAdminUiFieldGroupMeta = {
  __typename?: 'KeystoneAdminUIFieldGroupMeta';
  description?: Maybe<Scalars['String']>;
  fields: Array<KeystoneAdminUiFieldMeta>;
  label: Scalars['String'];
};

export type KeystoneAdminUiFieldMeta = {
  __typename?: 'KeystoneAdminUIFieldMeta';
  createView: KeystoneAdminUiFieldMetaCreateView;
  customViewsIndex?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fieldMeta?: Maybe<Scalars['JSON']>;
  isFilterable: Scalars['Boolean'];
  isOrderable: Scalars['Boolean'];
  itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
  label: Scalars['String'];
  listView: KeystoneAdminUiFieldMetaListView;
  path: Scalars['String'];
  search?: Maybe<QueryMode>;
  viewsIndex: Scalars['Int'];
};


export type KeystoneAdminUiFieldMetaItemViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type KeystoneAdminUiFieldMetaCreateView = {
  __typename?: 'KeystoneAdminUIFieldMetaCreateView';
  fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  EDIT = 'edit',
  HIDDEN = 'hidden'
}

export type KeystoneAdminUiFieldMetaItemView = {
  __typename?: 'KeystoneAdminUIFieldMetaItemView';
  fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
  fieldPosition?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldPosition>;
};

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  EDIT = 'edit',
  HIDDEN = 'hidden',
  READ = 'read'
}

export enum KeystoneAdminUiFieldMetaItemViewFieldPosition {
  FORM = 'form',
  SIDEBAR = 'sidebar'
}

export type KeystoneAdminUiFieldMetaListView = {
  __typename?: 'KeystoneAdminUIFieldMetaListView';
  fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  HIDDEN = 'hidden',
  READ = 'read'
}

export type KeystoneAdminUiListMeta = {
  __typename?: 'KeystoneAdminUIListMeta';
  description?: Maybe<Scalars['String']>;
  fields: Array<KeystoneAdminUiFieldMeta>;
  groups: Array<KeystoneAdminUiFieldGroupMeta>;
  hideCreate: Scalars['Boolean'];
  hideDelete: Scalars['Boolean'];
  initialColumns: Array<Scalars['String']>;
  initialSort?: Maybe<KeystoneAdminUiSort>;
  isHidden: Scalars['Boolean'];
  isSingleton: Scalars['Boolean'];
  itemQueryName: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  labelField: Scalars['String'];
  listQueryName: Scalars['String'];
  pageSize: Scalars['Int'];
  path: Scalars['String'];
  plural: Scalars['String'];
  singular: Scalars['String'];
};

export type KeystoneAdminUiSort = {
  __typename?: 'KeystoneAdminUISort';
  direction: KeystoneAdminUiSortDirection;
  field: Scalars['String'];
};

export enum KeystoneAdminUiSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type KeystoneMeta = {
  __typename?: 'KeystoneMeta';
  adminMeta: KeystoneAdminMeta;
};

export type MailCampaign = {
  __typename?: 'MailCampaign';
  date: Scalars['DateTime'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticateUserWithPassword?: Maybe<UserAuthenticationWithPasswordResult>;
  createAsset?: Maybe<Asset>;
  createAssets?: Maybe<Array<Maybe<Asset>>>;
  createBlock?: Maybe<Block>;
  createBlocks?: Maybe<Array<Maybe<Block>>>;
  createCampaign?: Maybe<Campaign>;
  createCampaigns?: Maybe<Array<Maybe<Campaign>>>;
  createDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>;
  createDocumentCategory?: Maybe<DocumentCategory>;
  createInitialUser: UserAuthenticationWithPasswordSuccess;
  createPeriod?: Maybe<Period>;
  createPeriods?: Maybe<Array<Maybe<Period>>>;
  createPopUps?: Maybe<Array<Maybe<PopUp>>>;
  createUser?: Maybe<User>;
  createUsers?: Maybe<Array<Maybe<User>>>;
  createpopUp?: Maybe<PopUp>;
  deleteAsset?: Maybe<Asset>;
  deleteAssets?: Maybe<Array<Maybe<Asset>>>;
  deleteBlock?: Maybe<Block>;
  deleteBlocks?: Maybe<Array<Maybe<Block>>>;
  deleteCampaign?: Maybe<Campaign>;
  deleteCampaigns?: Maybe<Array<Maybe<Campaign>>>;
  deleteDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>;
  deleteDocumentCategory?: Maybe<DocumentCategory>;
  deletePeriod?: Maybe<Period>;
  deletePeriods?: Maybe<Array<Maybe<Period>>>;
  deletePopUps?: Maybe<Array<Maybe<PopUp>>>;
  deleteUser?: Maybe<User>;
  deleteUsers?: Maybe<Array<Maybe<User>>>;
  deletepopUp?: Maybe<PopUp>;
  endSession: Scalars['Boolean'];
  updateAsset?: Maybe<Asset>;
  updateAssets?: Maybe<Array<Maybe<Asset>>>;
  updateBlock?: Maybe<Block>;
  updateBlocks?: Maybe<Array<Maybe<Block>>>;
  updateCampaign?: Maybe<Campaign>;
  updateCampaigns?: Maybe<Array<Maybe<Campaign>>>;
  updateDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>;
  updateDocumentCategory?: Maybe<DocumentCategory>;
  updatePeriod?: Maybe<Period>;
  updatePeriods?: Maybe<Array<Maybe<Period>>>;
  updatePopUps?: Maybe<Array<Maybe<PopUp>>>;
  updateUser?: Maybe<User>;
  updateUsers?: Maybe<Array<Maybe<User>>>;
  updatepopUp?: Maybe<PopUp>;
};


export type MutationAuthenticateUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};


export type MutationCreateAssetsArgs = {
  data: Array<AssetCreateInput>;
};


export type MutationCreateBlockArgs = {
  data: BlockCreateInput;
};


export type MutationCreateBlocksArgs = {
  data: Array<BlockCreateInput>;
};


export type MutationCreateCampaignArgs = {
  data: CampaignCreateInput;
};


export type MutationCreateCampaignsArgs = {
  data: Array<CampaignCreateInput>;
};


export type MutationCreateDocumentCategoriesArgs = {
  data: Array<DocumentCategoryCreateInput>;
};


export type MutationCreateDocumentCategoryArgs = {
  data: DocumentCategoryCreateInput;
};


export type MutationCreateInitialUserArgs = {
  data: CreateInitialUserInput;
};


export type MutationCreatePeriodArgs = {
  data: PeriodCreateInput;
};


export type MutationCreatePeriodsArgs = {
  data: Array<PeriodCreateInput>;
};


export type MutationCreatePopUpsArgs = {
  data: Array<PopUpCreateInput>;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUsersArgs = {
  data: Array<UserCreateInput>;
};


export type MutationCreatepopUpArgs = {
  data: PopUpCreateInput;
};


export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};


export type MutationDeleteAssetsArgs = {
  where: Array<AssetWhereUniqueInput>;
};


export type MutationDeleteBlockArgs = {
  where: BlockWhereUniqueInput;
};


export type MutationDeleteBlocksArgs = {
  where: Array<BlockWhereUniqueInput>;
};


export type MutationDeleteCampaignArgs = {
  where: CampaignWhereUniqueInput;
};


export type MutationDeleteCampaignsArgs = {
  where: Array<CampaignWhereUniqueInput>;
};


export type MutationDeleteDocumentCategoriesArgs = {
  where: Array<DocumentCategoryWhereUniqueInput>;
};


export type MutationDeleteDocumentCategoryArgs = {
  where: DocumentCategoryWhereUniqueInput;
};


export type MutationDeletePeriodArgs = {
  where: PeriodWhereUniqueInput;
};


export type MutationDeletePeriodsArgs = {
  where: Array<PeriodWhereUniqueInput>;
};


export type MutationDeletePopUpsArgs = {
  where: Array<PopUpWhereUniqueInput>;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteUsersArgs = {
  where: Array<UserWhereUniqueInput>;
};


export type MutationDeletepopUpArgs = {
  where: PopUpWhereUniqueInput;
};


export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};


export type MutationUpdateAssetsArgs = {
  data: Array<AssetUpdateArgs>;
};


export type MutationUpdateBlockArgs = {
  data: BlockUpdateInput;
  where: BlockWhereUniqueInput;
};


export type MutationUpdateBlocksArgs = {
  data: Array<BlockUpdateArgs>;
};


export type MutationUpdateCampaignArgs = {
  data: CampaignUpdateInput;
  where: CampaignWhereUniqueInput;
};


export type MutationUpdateCampaignsArgs = {
  data: Array<CampaignUpdateArgs>;
};


export type MutationUpdateDocumentCategoriesArgs = {
  data: Array<DocumentCategoryUpdateArgs>;
};


export type MutationUpdateDocumentCategoryArgs = {
  data: DocumentCategoryUpdateInput;
  where: DocumentCategoryWhereUniqueInput;
};


export type MutationUpdatePeriodArgs = {
  data: PeriodUpdateInput;
  where: PeriodWhereUniqueInput;
};


export type MutationUpdatePeriodsArgs = {
  data: Array<PeriodUpdateArgs>;
};


export type MutationUpdatePopUpsArgs = {
  data: Array<PopUpUpdateArgs>;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateUsersArgs = {
  data: Array<UserUpdateArgs>;
};


export type MutationUpdatepopUpArgs = {
  data: PopUpUpdateInput;
  where: PopUpWhereUniqueInput;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export type PasswordState = {
  __typename?: 'PasswordState';
  isSet: Scalars['Boolean'];
};

export type Period = {
  __typename?: 'Period';
  displayDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  period: Scalars['Int'];
};

export type PeriodCreateInput = {
  displayDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  period: Scalars['Int'];
};

export type PeriodOrderByInput = {
  displayDate?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  period?: InputMaybe<OrderDirection>;
};

export type PeriodUpdateArgs = {
  data: PeriodUpdateInput;
  where: PeriodWhereUniqueInput;
};

export type PeriodUpdateInput = {
  displayDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['Int']>;
};

export type PeriodWhereInput = {
  AND?: InputMaybe<Array<PeriodWhereInput>>;
  NOT?: InputMaybe<Array<PeriodWhereInput>>;
  OR?: InputMaybe<Array<PeriodWhereInput>>;
  displayDate?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  period?: InputMaybe<IntFilter>;
};

export type PeriodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  asset?: Maybe<Asset>;
  assets?: Maybe<Array<Asset>>;
  assetsCount?: Maybe<Scalars['Int']>;
  authenticatedItem?: Maybe<AuthenticatedItem>;
  block?: Maybe<Block>;
  blocks?: Maybe<Array<Block>>;
  blocksCount?: Maybe<Scalars['Int']>;
  campaign?: Maybe<Campaign>;
  campaigns?: Maybe<Array<Campaign>>;
  campaignsCount?: Maybe<Scalars['Int']>;
  documentCategories?: Maybe<Array<DocumentCategory>>;
  documentCategoriesCount?: Maybe<Scalars['Int']>;
  documentCategory?: Maybe<DocumentCategory>;
  getCampaigns?: Maybe<Array<Maybe<MailCampaign>>>;
  keystone: KeystoneMeta;
  period?: Maybe<Period>;
  periods?: Maybe<Array<Period>>;
  periodsCount?: Maybe<Scalars['Int']>;
  popUp?: Maybe<PopUp>;
  popUps?: Maybe<Array<PopUp>>;
  popUpsCount?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
  usersCount?: Maybe<Scalars['Int']>;
};


export type QueryAssetArgs = {
  where: AssetWhereUniqueInput;
};


export type QueryAssetsArgs = {
  orderBy?: Array<AssetOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: AssetWhereInput;
};


export type QueryAssetsCountArgs = {
  where?: AssetWhereInput;
};


export type QueryBlockArgs = {
  where: BlockWhereUniqueInput;
};


export type QueryBlocksArgs = {
  orderBy?: Array<BlockOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: BlockWhereInput;
};


export type QueryBlocksCountArgs = {
  where?: BlockWhereInput;
};


export type QueryCampaignArgs = {
  where: CampaignWhereUniqueInput;
};


export type QueryCampaignsArgs = {
  orderBy?: Array<CampaignOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: CampaignWhereInput;
};


export type QueryCampaignsCountArgs = {
  where?: CampaignWhereInput;
};


export type QueryDocumentCategoriesArgs = {
  orderBy?: Array<DocumentCategoryOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: DocumentCategoryWhereInput;
};


export type QueryDocumentCategoriesCountArgs = {
  where?: DocumentCategoryWhereInput;
};


export type QueryDocumentCategoryArgs = {
  where: DocumentCategoryWhereUniqueInput;
};


export type QueryPeriodArgs = {
  where: PeriodWhereUniqueInput;
};


export type QueryPeriodsArgs = {
  orderBy?: Array<PeriodOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PeriodWhereInput;
};


export type QueryPeriodsCountArgs = {
  where?: PeriodWhereInput;
};


export type QueryPopUpArgs = {
  where: PopUpWhereUniqueInput;
};


export type QueryPopUpsArgs = {
  orderBy?: Array<PopUpOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: PopUpWhereInput;
};


export type QueryPopUpsCountArgs = {
  where?: PopUpWhereInput;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  orderBy?: Array<UserOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: UserWhereInput;
};


export type QueryUsersCountArgs = {
  where?: UserWhereInput;
};

export enum QueryMode {
  DEFAULT = 'default',
  INSENSITIVE = 'insensitive'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<PasswordState>;
};

export type UserAuthenticationWithPasswordFailure = {
  __typename?: 'UserAuthenticationWithPasswordFailure';
  message: Scalars['String'];
};

export type UserAuthenticationWithPasswordResult = UserAuthenticationWithPasswordFailure | UserAuthenticationWithPasswordSuccess;

export type UserAuthenticationWithPasswordSuccess = {
  __typename?: 'UserAuthenticationWithPasswordSuccess';
  item: User;
  sessionToken: Scalars['String'];
};

export type UserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UserOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  email?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type UserUpdateArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type PopUp = {
  __typename?: 'popUp';
  active?: Maybe<Scalars['Boolean']>;
  coverText?: Maybe<PopUp_CoverText_Document>;
  id: Scalars['ID'];
};

export type PopUpCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  coverText?: InputMaybe<Scalars['JSON']>;
};

export type PopUpOrderByInput = {
  active?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
};

export type PopUpUpdateArgs = {
  data: PopUpUpdateInput;
  where: PopUpWhereUniqueInput;
};

export type PopUpUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  coverText?: InputMaybe<Scalars['JSON']>;
};

export type PopUpWhereInput = {
  AND?: InputMaybe<Array<PopUpWhereInput>>;
  NOT?: InputMaybe<Array<PopUpWhereInput>>;
  OR?: InputMaybe<Array<PopUpWhereInput>>;
  active?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
};

export type PopUpWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PopUp_CoverText_Document = {
  __typename?: 'popUp_coverText_Document';
  document: Scalars['JSON'];
};


export type PopUp_CoverText_DocumentDocumentArgs = {
  hydrateRelationships?: Scalars['Boolean'];
};

export type WelcomeModalComponent_PopupQueryVariables = Exact<{ [key: string]: never; }>;


export type WelcomeModalComponent_PopupQuery = { __typename?: 'Query', popUps?: Array<{ __typename?: 'popUp', id: string, coverText?: { __typename?: 'popUp_coverText_Document', document: any } | null }> | null };

export type HeaderComponent_PeriodsQueryVariables = Exact<{ [key: string]: never; }>;


export type HeaderComponent_PeriodsQuery = { __typename?: 'Query', periods?: Array<{ __typename?: 'Period', id: string, period: number, name?: string | null, displayDate?: string | null }> | null };

export type HomeComponent_PopupQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeComponent_PopupQuery = { __typename?: 'Query', popUps?: Array<{ __typename?: 'popUp', id: string, active?: boolean | null, coverText?: { __typename?: 'popUp_coverText_Document', document: any } | null }> | null };

export type DocumentsComponent_DocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentsComponent_DocumentsQuery = { __typename?: 'Query', assets?: Array<{ __typename?: 'Asset', id: string, name: string, type: AssetTypeType, file?: { __typename?: 'FileFieldOutput', url: string } | null, category?: { __typename?: 'DocumentCategory', name: string, ordering: number } | null }> | null };

export type NewsletterComponent_NewsletterQueryVariables = Exact<{ [key: string]: never; }>;


export type NewsletterComponent_NewsletterQuery = { __typename?: 'Query', getCampaigns?: Array<{ __typename?: 'MailCampaign', date: Date | string, title: string, url: string } | null> | null };

export type ThemesComponent_ThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemesComponent_ThemesQuery = { __typename?: 'Query', assets?: Array<{ __typename?: 'Asset', id: string, name: string, type: AssetTypeType, file?: { __typename?: 'FileFieldOutput', url: string } | null }> | null };

export type VideosComponent_VideosQueryVariables = Exact<{ [key: string]: never; }>;


export type VideosComponent_VideosQuery = { __typename?: 'Query', assets?: Array<{ __typename?: 'Asset', id: string, name: string, type: AssetTypeType, videoUrl?: string | null, file?: { __typename?: 'FileFieldOutput', url: string } | null }> | null };

export type TimelineComponent_BlocksQueryVariables = Exact<{ [key: string]: never; }>;


export type TimelineComponent_BlocksQuery = { __typename?: 'Query', blocks?: Array<{ __typename?: 'Block', id: string, category: BlockCategoryType, videoUrlCode?: string | null, beginDate: string, endDate: string, period: number, gathering?: boolean | null, gatheringDate?: string | null, coverText?: { __typename?: 'Block_coverText_Document', document: any } | null, details?: { __typename?: 'Block_details_Document', document: any } | null, downloadLink?: { __typename?: 'FileFieldOutput', url: string } | null }> | null };


export const WelcomeModalComponent_PopupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WelcomeModalComponent_popup"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"popUps"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"coverText"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"document"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"hydrateRelationships"},"value":{"kind":"BooleanValue","value":true}}]}]}}]}}]}}]} as unknown as DocumentNode<WelcomeModalComponent_PopupQuery, WelcomeModalComponent_PopupQueryVariables>;
export const HeaderComponent_PeriodsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"HeaderComponent_periods"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"periods"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"displayDate"}}]}}]}}]} as unknown as DocumentNode<HeaderComponent_PeriodsQuery, HeaderComponent_PeriodsQueryVariables>;
export const HomeComponent_PopupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"HomeComponent_popup"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"popUps"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"coverText"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"document"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"hydrateRelationships"},"value":{"kind":"BooleanValue","value":true}}]}]}}]}}]}}]} as unknown as DocumentNode<HomeComponent_PopupQuery, HomeComponent_PopupQueryVariables>;
export const DocumentsComponent_DocumentsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DocumentsComponent_documents"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"equals"},"value":{"kind":"EnumValue","value":"DOCUMENT"}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}},{"kind":"Field","name":{"kind":"Name","value":"category"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"ordering"}}]}}]}}]}}]} as unknown as DocumentNode<DocumentsComponent_DocumentsQuery, DocumentsComponent_DocumentsQueryVariables>;
export const NewsletterComponent_NewsletterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NewsletterComponent_newsletter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCampaigns"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"date"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode<NewsletterComponent_NewsletterQuery, NewsletterComponent_NewsletterQueryVariables>;
export const ThemesComponent_ThemesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ThemesComponent_themes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"equals"},"value":{"kind":"EnumValue","value":"THEME"}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode<ThemesComponent_ThemesQuery, ThemesComponent_ThemesQueryVariables>;
export const VideosComponent_VideosDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"VideosComponent_videos"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"where"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"equals"},"value":{"kind":"EnumValue","value":"VIDEO"}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"file"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}},{"kind":"Field","name":{"kind":"Name","value":"videoUrl"}}]}}]}}]} as unknown as DocumentNode<VideosComponent_VideosQuery, VideosComponent_VideosQueryVariables>;
export const TimelineComponent_BlocksDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"TimelineComponent_blocks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"blocks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"category"}},{"kind":"Field","name":{"kind":"Name","value":"coverText"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"document"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"hydrateRelationships"},"value":{"kind":"BooleanValue","value":true}}]}]}},{"kind":"Field","name":{"kind":"Name","value":"details"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"document"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"hydrateRelationships"},"value":{"kind":"BooleanValue","value":true}}]}]}},{"kind":"Field","name":{"kind":"Name","value":"videoUrlCode"}},{"kind":"Field","name":{"kind":"Name","value":"beginDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"gathering"}},{"kind":"Field","name":{"kind":"Name","value":"gatheringDate"}},{"kind":"Field","name":{"kind":"Name","value":"downloadLink"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]}}]} as unknown as DocumentNode<TimelineComponent_BlocksQuery, TimelineComponent_BlocksQueryVariables>;