<div class="day-wrapper">

  <div class="row">
    <app-standard-block [block]="block" *ngFor="let block of type3Block"></app-standard-block>
    <div class="block-line" *ngIf="type3Block.length > 0" [ngClass]="'block-line-type-' + type3Block[0].category"></div>
    <div class="block-bullet" [class.active]="activeIndex" *ngIf="type3Block.length > 0" [ngClass]="'block-bullet-type-' + type3Block[0].category">
      <span class="active-bullet"></span>
    </div>
  </div>

  <div class="row" [class.small-indent]="type3Block.length > 0">
    <app-standard-block [block]="block" *ngFor="let block of type2Block"></app-standard-block>
    <div class="block-line" *ngIf="type2Block.length > 0" [ngClass]="'block-line-type-' + type2Block[0].category"></div>
    <div class="block-bullet" [class.active]="activeIndex && type1Block.length === 0 && type3Block.length === 0" *ngIf="type2Block.length > 0" [ngClass]="'block-bullet-type-' + type2Block[0].category">
      <span class="active-bullet"></span>
    </div>
  </div>

  <div class="row" [class.small-indent]="type3Block.length > 0 || type2Block.length > 0" [class.big-indent]="type3Block.length > 0 && type2Block.length > 0">
    <app-standard-block [block]="block" *ngFor="let block of type1Block"></app-standard-block>
    <div class="block-line" *ngIf="type1Block.length > 0" [ngClass]="'block-line-type-' + type1Block[0].category"></div>
    <div class="block-bullet" [class.active]="activeIndex  && type3Block.length === 0 && type2Block.length === 0" *ngIf="type1Block.length > 0" [ngClass]="'block-bullet-type-' + type1Block[0].category">
      <span class="active-bullet"></span>
    </div>
  </div>
</div>

<div class="period" *ngIf="period">
  <div class="period-number" [ngStyle]="{'bottom': 210 - (20 * period) + 'px'}">
    <p>{{ period }}</p>
  </div>
  <div class="period-line" [ngStyle]="{'height': 180 - (20 * period) + 'px'}"></div>
  <div class="period-bullet"></div>
</div>

