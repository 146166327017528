import {
  AfterViewInit,
  Component, ElementRef, Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import React from 'react';
import {createRoot, Root} from 'react-dom/client';
import {DocumentRenderer, DocumentRendererProps} from '@keystone-6/document-renderer';
import {AssetTypeType} from '../../../../gql/graphql';

window.React = React;

@Component({
  selector: 'app-document-renderer',
  template: `<span #containerEl class="document"></span>`,
  styleUrls: ['./document-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentRendererComponent implements OnChanges, OnDestroy, AfterViewInit {

  root: Root | null = null;
  @ViewChild('containerEl', {static: false}) containerRef!: ElementRef<HTMLSpanElement>;
  @Input() public document: any;

  renderers: DocumentRendererProps['renderers'] = {
    inline: {
      relationship({ relationship, data }) {
        if (relationship === 'mention') {
          if (data === null || data.data === undefined) {
            return <span></span>
          } else if (data.data['type'] === AssetTypeType.IMAGE) {
            return <img src={`${data.data['file'].url}`} alt=""/>;
          } else if (data.data['type'] === AssetTypeType.DOCUMENT) {
            return <a href={data.data['file'].url}><i className="fa-light fa-download"/>{data.data['name']}</a>
          }
        }

        return null;
      },
    },
  };

  constructor() { }

  ngAfterViewInit(): void {
    this.root = createRoot(this.containerRef.nativeElement);
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngOnDestroy(): void {
    this.root?.unmount();
  }

  private render() {
    this.root?.render(<DocumentRenderer document={this.document} renderers={this.renderers}/>)
  }
}
