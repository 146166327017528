import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Apollo} from 'apollo-angular';
import {gql} from '../../../../gql';
import {catchError, EMPTY, Subscription} from 'rxjs';
import {WelcomeModalComponent_PopupQuery} from '../../../../gql/graphql';

const POPUP_QUERY = gql(/* GraphQL */ `
  query WelcomeModalComponent_popup {
    popUps {
      id
      coverText {
        document(hydrateRelationships: true)
      }
    }
  }
`);
// @ts-expect-error [0] does not exist on type
export type PopUpText = WelcomeModalComponent_PopupQuery['popUps'][0]['coverText']['document'];

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  popUpText: PopUpText | null = null;

  constructor(public dialogRef: MatDialogRef<WelcomeModalComponent>,
              public apollo: Apollo) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.apollo.watchQuery({
        query: POPUP_QUERY
      }).valueChanges.pipe(
        catchError(error => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(output => {
        this.popUpText = output.data?.popUps?.[0]?.coverText ?? null;
      })
    );
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
