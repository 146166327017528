import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { catchError, EMPTY, map, Subscription } from 'rxjs';
import { gql } from '../../../../gql';
import { VideosComponent_VideosQuery } from '../../../../gql/graphql';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const VIDEO_QUERY = gql(/* GraphQL */`
  query VideosComponent_videos {
    assets(where: { type: { equals: VIDEO } }) {
      id
      name
      type
      file {
        url
      }
      videoUrl
    }
  }
`);

export type AssetType = NonNullable<VideosComponent_VideosQuery['assets']>[0] & {
  vimeoUrl?: SafeResourceUrl | null;
};

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  subscriptions: Subscription[] = [];
  videos: AssetType[] = [];

  constructor(private apollo: Apollo,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.apollo.watchQuery<VideosComponent_VideosQuery>({
        query: VIDEO_QUERY
      }).valueChanges.pipe(
        catchError(error => {
          console.error(error);
          return EMPTY;
        }),
        map(output => {
          const assets = output.data.assets;
          if (assets) {
            return assets.map(asset => ({
              ...asset,
              vimeoUrl: asset.videoUrl !== '' ? this.sanitizeVimeoUrl('https://player.vimeo.com/video/' + asset.videoUrl + '?title=0&byline=0&portrait=0') : null
            }));
          }
          return [];
        })
      ).subscribe((videos: AssetType[]) => {
        this.videos = videos;
      })
    )
  }

  private sanitizeVimeoUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
