import {Component, OnDestroy, OnInit} from '@angular/core';
import {BlockType} from '../../timeline/timeline.component';
import {BlockDetailSelection, BlockDetailService} from '../../../shared/services/block-detail.service';
import {WelcomeModalComponent} from '../../../shared/modals/welcome-modal/welcome-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {catchError, EMPTY, Subscription} from 'rxjs';
import {gql} from '../../../../gql';
import {WelcomeModalComponent_PopupQuery} from '../../../../gql/graphql';
import {Apollo} from 'apollo-angular';

const POPUP_ACTIVE_QUERY = gql(/* GraphQL */ `
  query HomeComponent_popup {
    popUps {
      id
      active
      coverText {
        document(hydrateRelationships: true)
      }
    }
  }
`);
// @ts-expect-error [0] does not exist on type
export type PopUpActive = WelcomeModalComponent_PopupQuery['popUps'][0];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  block: BlockType | null = null;
  showBlockDetail = false;
  subscriptions: Subscription[] = [];

  constructor(private blockDetailService: BlockDetailService,
              private dialog: MatDialog,
              private apollo: Apollo) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.apollo.watchQuery({
        query: POPUP_ACTIVE_QUERY
      }).valueChanges.pipe(
        catchError(error => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(result => {
        const popUps = result.data?.popUps;

        if (popUps && popUps.length > 0) {
          const coverText = JSON.stringify(popUps[0]?.coverText?.document);
          const storedCoverText = localStorage.getItem('welcome-modal') || null;

          if (coverText && coverText !== storedCoverText) {
            const isActive = popUps[0].active;

            if (isActive) {
              localStorage.setItem('welcome-modal', coverText);

              const dialogRef = this.dialog.open(WelcomeModalComponent, {
                width: '1000px',
                height: '500px',
                hasBackdrop: true
              });

              this.subscriptions.push(
                dialogRef.backdropClick().subscribe(() => {
                  dialogRef.close();
                })
              );
            }
          }
        }
      }),

      this.blockDetailService.getBlockDetailSelection().subscribe((output: BlockDetailSelection) => {
        this.block = output.block;
        this.showBlockDetail = output.showBlockDetail;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
