<div class="header">
  <a href="/">
    <img src="assets/imgs/bovo-icon.svg" alt="" routerLink="">
  </a>
  <div class="timeline-progress">
    <div class="time-periods">
      <div class="period" *ngFor="let period of periods" [class.active]="activePeriod === period.period" (click)="setActivePeriod(period.period)">
        <div class="period-number">
          <p>{{ period.period }}</p>
        </div>
        <p class="period-title" (mouseover)="period.showDate = true" (mouseout)="period.showDate = false">
          {{ period.showDate ? period.displayDate : period.name }}
        </p>
      </div>
    </div>

    <div class="progress-bar">
      <div class="active-bar" [ngStyle]="{'width': 10 + (20 * (activePeriod - 1)) + '%'}"></div>
    </div>
  </div>
  <i class="fa-solid fa-search search-icon" (click)="openSearchModal()"></i>

</div>
