<div class="wrapper">
  <div class="header">
    <form (submit)="preventDefault($event)">
      <input type="text" placeholder="Zoek op een titel" [formControl]="search">
      <i class="fa-solid fa-xmark close-icon" (click)="closeDialog()"></i>
    </form>
  </div>


  <h2>Gevonden items</h2>
  <p *ngIf="search.value === '' || search.value === null">Type een zoekwoord in de zoekbalk</p>
  <p *ngIf="search.value !== '' && search.value !== null && filteredBlocks.length === 0 && emptyResults === false">Aan het zoeken...</p>
  <p *ngIf="emptyResults && search.value !== '' && search.value !== null">Niks gevonden...</p>
  <div class="block-wrapper">
    <div class="block" *ngFor="let block of filteredBlocks" (click)="goToBlock(block.id)">
      <div class="time-wrapper">
        <p class="begin-date">{{ block.beginDate }}</p>
        <p class="period">Periode {{ block.period }}</p>
      </div>
      <div class="title" *ngIf="block.coverText.document.length > 0" [appHighlight]="highlightedSearch">
        {{ block.coverText.document[0].children?.[0].text }}
      </div>
      <div class="paragraphs">
        <ng-container *ngFor="let paragraph of block.coverText.document.slice(1)">
          <app-document-renderer [appHighlight]="highlightedSearch" [document]="[paragraph]"></app-document-renderer>
        </ng-container>
      </div>
      <i class="fa-light fa-circle-arrow-right link-icon"></i>
    </div>
  </div>
</div>
