<a href="https://laposta.nl/f/ssypsbnntmjo" target="_blank" class="newsletter-button">Inschrijven voor nieuwsbrief</a>

<div class="header">
  <h3 class="title">Nieuwsbrief</h3>
  <h3 class="date">Datum</h3>
</div>
<div class="newsletter" *ngFor="let newsletter of newsletters">
  <a class="title" href="{{ newsletter.url }}" target="_blank">{{ newsletter.title }}</a>
  <p class="date">{{ newsletter.date | date: 'dd-MM-YYYY'}}</p>
</div>
