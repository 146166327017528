<div class="block" *ngIf="block" [class.gathering]="block.gathering" [ngClass]="block.category" [ngStyle]="{ 'animation': showActive && animationActive ? getAnimationStyle() + '1000ms infinite' : ''}">
  <i *ngIf="copied" class="fa-solid fa-check share-icon" [class.gathering]="block.gathering" [ngClass]="block.category"></i>
  <span *ngIf="copied" class="share-icon copied-text" [class.gathering]="block.gathering" [ngClass]="block.category">Gekopieerd</span>
  <i *ngIf="!copied" class="fa-regular fa-link share-icon" [class.gathering]="block.gathering" [ngClass]="block.category" (click)="copyToClipboard()"></i>
  <p class="date" *ngIf="block.gatheringDate">{{ gatheringDate | date: 'd MMMM' }}</p>
  <p class="date" *ngIf="!block.gatheringDate" [ngClass]="block.category">Van {{ beginDate | date: 'd MMMM' }} tot {{ endDate | date: 'd MMMM' }}</p>
  <div class="text-wrapper" [ngClass]="block.category">
    <app-document-renderer [document]="block.coverText.document"></app-document-renderer>
    <div class="flex-wrapper">
      <a *ngIf="block.details" (click)="toggleDetailView()">Meer informatie <i class="fa-light fa-circle-arrow-down"></i></a>
      <a class="download-button" *ngIf="block.downloadLink" [href]="block.downloadLink.url"><i class="fa-light fa-download download-icon"></i>Download </a>

    </div>
  </div>
</div>

