import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {BlockType} from '../timeline.component';
import {BlockCategoryType} from '../../../../gql/graphql';

@Component({
  selector: 'app-timeline-day',
  templateUrl: './timeline-day.component.html',
  styleUrls: ['./timeline-day.component.scss']
})
export class TimelineDayComponent implements OnInit {
  @Input() blocks: BlockType[] = [];
  @Input() activeIndex = true;
  @Input() period: number | null = null;
  type1Block: BlockType[] = [];
  type2Block: BlockType[] = [];
  type3Block: BlockType[] = [];

  constructor(
    // this is needed so the component can be reached as element in timeline.component
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.blocks.forEach((block: BlockType) => {
      if (block.category === BlockCategoryType.PO) {
        this.type1Block.push(block)
      } else if (block.category === BlockCategoryType.VO) {
        this.type2Block.push(block)
      } else if (block.category === BlockCategoryType.POVO) {
        this.type3Block.push(block)
      }
    });
  }
}
