import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GraphQLError} from 'graphql/error';
import {buffer, debounceTime, EMPTY, Observable, Subject} from 'rxjs';
import {DialogService} from './dialog.service';
import {ErrorDialogComponent} from '../../dialogs/error-dialog/error-dialog.component';
import {notNullOrUndefined} from '../utils';

export type AppError = {problem: string, message: string};

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  incomingErrors$ = new Subject<AppError>();

  constructor(private dialogService: DialogService) {
    this.incomingErrors$
      .pipe(buffer(this.incomingErrors$.pipe(debounceTime(500))))
      .subscribe(errors => this.showErrors(errors));
  }

  handleMutationProblems(form: FormGroup, fieldErrors: Array<{ field: string; problem: string }> | null | undefined, graphqlErrors: ReadonlyArray<GraphQLError> | undefined): {hadProblems: boolean} {
    let problemsEncountered = false;

    if (notNullOrUndefined(fieldErrors)) {
      fieldErrors.forEach(fieldError => {
        const control = form.get(fieldError.field);
        if (control !== null) {
          control.setErrors({ message: fieldError.problem });
          control.markAllAsTouched();
        }
      });

      problemsEncountered = fieldErrors.length > 0;
    }

    return {hadProblems: problemsEncountered};
  }

  private showErrors(errors: AppError[]) {
    this.dialogService.openOverlay(ErrorDialogComponent, errors);
  }

  public HandleGraphQLError(whatFailed: string): Observable<never> {
    const endsOnDot = whatFailed.slice(-1) === '.';
    const location = endsOnDot ? whatFailed.slice(0, -1) : whatFailed;
    this.displayError(location, 'Door een onverwachte fout konden geen gegevens worden opgehaald/aangepast.');
    return EMPTY;
  }

  public displayError(problem: string, message: string): void {
    this.incomingErrors$.next({problem, message});
  }
}
