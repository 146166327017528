
<a class="date-block" href="/">
  <h2>Vandaag is het</h2>
  <h2 class="bold-date">{{ today | date: 'd MMMM' }}</h2>
</a>

<div class="time-line-filters">
  <div class="small-filter-block-wrapper">
    <div class="small-filter-block left" (click)="setTimelineFilter(blockCategoryType.PO)" [class.active]="activeCategory === blockCategoryType.PO">
      <h3>Tijdlijn primair onderwijs</h3>
    </div>
    <div class="small-filter-block right" (click)="setTimelineFilter(blockCategoryType.VO)" [class.active]="activeCategory === blockCategoryType.VO">
      <h3>Tijdlijn voortgezet onderwijs</h3>
    </div>
  </div>

  <div class="long-filter-block" (click)="setTimelineFilter(blockCategoryType.POVO)" [class.active]="activeCategory === blockCategoryType.POVO">
    <h3>Tijdlijn po en vo</h3>
  </div>
</div>

<div class="link-block">
  <ul>
    <li>
      <a routerLink="/details/documents"><i class="fa-light fa-arrow-down-to-bracket"></i> Alle documenten</a>
    </li>
    <li>
      <a routerLink="/details/videos"><i class="fa-regular fa-circle-play"></i> Alle video's</a>
    </li>
    <li>
      <a routerLink="/details/themes"><i class="fa-regular fa-circle-info"></i> Thema's</a>
    </li>
    <li>
      <a routerLink="/details/newsletters"><i class="fa-light fa-envelope"></i> Nieuwsbrieven</a>
    </li>
  </ul>
</div>

<div class="more-info-block">
  <ul>
    <li>
      <a href="https://www.bovohaaglanden.nl/" target="_blank">Meer informatie over de BOVO procedure</a>
    </li>
    <li>
      <a href="https://bovohaaglanden.nl/over-bovo/contact/" target="_blank">Contact</a>
    </li>
  </ul>
</div>
