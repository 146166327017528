
<div class="wrapper">
  <app-side-nav></app-side-nav>

  <div class="container">
    <div class="back-button" routerLink="">
      <i class="fa-regular fa-arrow-left"></i>
      <p>Terug</p>
    </div>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

