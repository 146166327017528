<div class="wrapper" #wrapper>

  <div class="container" #container *ngIf="_block">
    <app-document-renderer [document]="_block.details.document"></app-document-renderer>
    <iframe *ngIf="_block.videoUrlCode"
            width="560"
            height="315"
            [src]=videoUrl
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
    ></iframe>
  </div>
</div>
