<div class="background">
  <div class="middle">
    <div class="form-wrapper">
      <h1>Vul het <b>wachtwoord</b> in om verder te gaan naar <b>de online werkomgeving</b></h1>
      <input id="password" type="password" placeholder="Wachtwoord" #passwordInput (keyup.enter)="login(passwordInput.value)" [class.wrong-password]="wrongPassword">
      <label for="password" *ngIf="wrongPassword">Wachtwoord is onjuist</label>
      <p class="text-bold">Ben je een ouder en wil je meer informatie over Bovo Haaglanden of de procedure van 2023-2024?</p>
      <p class="text-regular">Ga dan naar <a href="https://www.bovohaaglanden.nl" target="_blank">www.bovohaaglanden.nl</a> voor informatie, of neem contact op met je school.</p>
    </div>
    <img src="assets/imgs/inlog/Vormen-inlog-01.png" alt="">
    <img class="top-right" src="assets/imgs/inlog/Vormen-inlog-03.png" alt="">
    <img class="bottom-right" src="assets/imgs/inlog/Vormen-inlog-04.png" alt="">
    <img class="bottom-left" src="assets/imgs/inlog/Vormen-inlog-05.png" alt="">
    <div class="top-left">
      <div class="img-wrapper">
        <img src="assets/imgs/inlog/Vormen-inlog-02.png" alt="">
        <img class="bovo-logo" src="assets/imgs/inlog/bovo-logo.png" alt="">
      </div>
    </div>
  </div>
</div>
