import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BlockCategoryType} from '../../../gql/graphql';

@Injectable({
  providedIn: 'root'
})
export class TimelineFilterService {

  private timelineFilter$ = new Subject<BlockCategoryType>();

  setTimelineFilter(filter: BlockCategoryType): void {
    this.timelineFilter$.next(filter);
  }

  getTimelineFilter(): Observable<BlockCategoryType> {
    return this.timelineFilter$;
  }
}
