import { Component } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  wrongPassword = false;
  password = '24Overstap25!';

  constructor(private router: Router) { }

  login(password: string) {
    if (password === this.password) {
      this.wrongPassword = false;
      localStorage.setItem('loggedIn', 'true');
      this.router.navigate([''])
    } else {
      this.wrongPassword = true;
    }
  }
}
