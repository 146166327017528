import {Component, EventEmitter} from '@angular/core';
import {OverlayComponent} from '../../shared/services/dialog.service';
import {AppError} from '../../shared/services/error.service';

@Component({
  selector: 'app-error-dialog-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OverlayComponent<AppError[], void> {
  errors: AppError[] | null = null;

  close$ = new EventEmitter<void>();

  receiveInput(input: AppError[]): void {
    this.errors = input;
  }

  reload() {
    location.reload();
  }
}
