<h1>Video's</h1>
<div class="videos" *ngFor="let video of videos">
  <h2>{{ video.name }}</h2>
  <iframe *ngIf="video.vimeoUrl"
          width="560"
          height="315"
          [src]=video.vimeoUrl
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
  ></iframe>
</div>
