import {Component, OnDestroy, OnInit} from '@angular/core';
import {gql} from '../../../../gql';
import {catchError, EMPTY, Subscription} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {AssetType} from '../documents/documents.component';
import {ThemesComponent_ThemesQuery} from '../../../../gql/graphql';

const THEMES_QUERY = gql(/* GraphQL */`
  query ThemesComponent_themes {
    assets(where: { type: { equals: THEME}}) {
      id
      name
      type
      file {
        url
      }
    }
  }
`);

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss']
})
export class ThemesComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  themes: AssetType[] = [];
  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.apollo.watchQuery<ThemesComponent_ThemesQuery>({
        query: THEMES_QUERY
      }).valueChanges.pipe(
        catchError(error => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(output => {
        if (output.data.assets) {
          this.themes = [...output.data.assets];
          this.themes.sort((a, b) => a.name.localeCompare(b.name))
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
