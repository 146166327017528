import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BlockType} from '../../views/timeline/timeline.component';

export interface BlockDetailSelection {
  block: BlockType;
  showBlockDetail: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BlockDetailService {

  private blockDetailSelection$ = new Subject<BlockDetailSelection>()

  setBlockDetailSelection(block: BlockType, showBlockDetail: boolean): void {
    const blockDetailSelection: BlockDetailSelection = {block, showBlockDetail};
    this.blockDetailSelection$.next(blockDetailSelection);
  }

  getBlockDetailSelection(): Observable<BlockDetailSelection> {
    return this.blockDetailSelection$;
  }
}
