/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query WelcomeModalComponent_popup {\n    popUps {\n      id\n      coverText {\n        document(hydrateRelationships: true)\n      }\n    }\n  }\n": types.WelcomeModalComponent_PopupDocument,
    "\n    query HeaderComponent_periods {\n      periods {\n        id\n        period\n        name\n        displayDate\n      }\n    }\n": types.HeaderComponent_PeriodsDocument,
    "\n  query HomeComponent_popup {\n    popUps {\n      id\n      active\n      coverText {\n        document(hydrateRelationships: true)\n      }\n    }\n  }\n": types.HomeComponent_PopupDocument,
    "\n query DocumentsComponent_documents {\n   assets(where: { type: { equals: DOCUMENT } }) {\n     id\n     name\n     type\n     file {\n       url\n     }\n     category {\n       name\n       ordering\n     }\n   }\n }\n": types.DocumentsComponent_DocumentsDocument,
    "\n    query NewsletterComponent_newsletter {\n      getCampaigns {\n        date\n        title\n        url\n      }\n    }\n": types.NewsletterComponent_NewsletterDocument,
    "\n  query ThemesComponent_themes {\n    assets(where: { type: { equals: THEME}}) {\n      id\n      name\n      type\n      file {\n        url\n      }\n    }\n  }\n": types.ThemesComponent_ThemesDocument,
    "\n  query VideosComponent_videos {\n    assets(where: { type: { equals: VIDEO } }) {\n      id\n      name\n      type\n      file {\n        url\n      }\n      videoUrl\n    }\n  }\n": types.VideosComponent_VideosDocument,
    "\n  query TimelineComponent_blocks {\n    blocks {\n      id\n      category\n      coverText {\n        document(hydrateRelationships: true)\n      }\n      details {\n        document(hydrateRelationships: true)\n      }\n      videoUrlCode\n      beginDate\n      endDate\n      period\n      gathering\n      gatheringDate\n      downloadLink {\n        url\n      }\n    }\n  }\n": types.TimelineComponent_BlocksDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query WelcomeModalComponent_popup {\n    popUps {\n      id\n      coverText {\n        document(hydrateRelationships: true)\n      }\n    }\n  }\n"): (typeof documents)["\n  query WelcomeModalComponent_popup {\n    popUps {\n      id\n      coverText {\n        document(hydrateRelationships: true)\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query HeaderComponent_periods {\n      periods {\n        id\n        period\n        name\n        displayDate\n      }\n    }\n"): (typeof documents)["\n    query HeaderComponent_periods {\n      periods {\n        id\n        period\n        name\n        displayDate\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query HomeComponent_popup {\n    popUps {\n      id\n      active\n      coverText {\n        document(hydrateRelationships: true)\n      }\n    }\n  }\n"): (typeof documents)["\n  query HomeComponent_popup {\n    popUps {\n      id\n      active\n      coverText {\n        document(hydrateRelationships: true)\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query DocumentsComponent_documents {\n   assets(where: { type: { equals: DOCUMENT } }) {\n     id\n     name\n     type\n     file {\n       url\n     }\n     category {\n       name\n       ordering\n     }\n   }\n }\n"): (typeof documents)["\n query DocumentsComponent_documents {\n   assets(where: { type: { equals: DOCUMENT } }) {\n     id\n     name\n     type\n     file {\n       url\n     }\n     category {\n       name\n       ordering\n     }\n   }\n }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query NewsletterComponent_newsletter {\n      getCampaigns {\n        date\n        title\n        url\n      }\n    }\n"): (typeof documents)["\n    query NewsletterComponent_newsletter {\n      getCampaigns {\n        date\n        title\n        url\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ThemesComponent_themes {\n    assets(where: { type: { equals: THEME}}) {\n      id\n      name\n      type\n      file {\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query ThemesComponent_themes {\n    assets(where: { type: { equals: THEME}}) {\n      id\n      name\n      type\n      file {\n        url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query VideosComponent_videos {\n    assets(where: { type: { equals: VIDEO } }) {\n      id\n      name\n      type\n      file {\n        url\n      }\n      videoUrl\n    }\n  }\n"): (typeof documents)["\n  query VideosComponent_videos {\n    assets(where: { type: { equals: VIDEO } }) {\n      id\n      name\n      type\n      file {\n        url\n      }\n      videoUrl\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TimelineComponent_blocks {\n    blocks {\n      id\n      category\n      coverText {\n        document(hydrateRelationships: true)\n      }\n      details {\n        document(hydrateRelationships: true)\n      }\n      videoUrlCode\n      beginDate\n      endDate\n      period\n      gathering\n      gatheringDate\n      downloadLink {\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query TimelineComponent_blocks {\n    blocks {\n      id\n      category\n      coverText {\n        document(hydrateRelationships: true)\n      }\n      details {\n        document(hydrateRelationships: true)\n      }\n      videoUrlCode\n      beginDate\n      endDate\n      period\n      gathering\n      gatheringDate\n      downloadLink {\n        url\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;